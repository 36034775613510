.download {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 80%;
  z-index: -99;
}
.download .middle {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

.download .middle .middle-wrapper {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.download .middle .middle-wrapper .iphone-wrapper {
  margin-right: 60px;
}

.iphone {
  width: 300px;
  height: 596px;
  position: relative;
}

.iphone .iphone-mp4 {
  position: absolute;
  width: 262px;
  height: 470px;
  margin: 10px 16px 10px 20px;
}

.iphone .iphone-case {
  position: absolute;
  width: 300px;
  height: 596px;
  // background-image: url(./images/iphone.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.download .middle .middle-wrapper .description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.download .middle .middle-wrapper .description .logo-big {
  width: 143px;
  height: 50px;
  // background-image: url(./images/ozink.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
}
.download .middle .middle-wrapper .description .lifestyle {
  white-space: nowrap;
  color: #fff;
  font-size: 36px;
  font-weight: 500;
  margin: 13px 0 0;
}
.download .middle .middle-wrapper .description .lifestyle-english {
  white-space: nowrap;
  color: #fff;
  font-size: 14px;
  font-family: Helvetica, Arial, sans-serif;
  font-style: oblique;
  font-weight: 400;
  margin: 20px 0 0;
}
.download .middle .middle-wrapper .description .qrcodes {
  margin-top: 36px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.download .middle .middle-wrapper .description .qrcodes a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  border-radius: 20px;
  background-color: #fff;
  margin-right: 15px;
  cursor: pointer;
  position: relative;
  color: #000;
  font-size: 16px;
}
.download .middle .middle-wrapper .description .qrcodes a span {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 6px;
}
.download .middle .middle-wrapper .description .qrcodes .qr-img {
  content: '';
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  height: 160px;
  padding-top: 20px;
  border-radius: 0 0 20px 20px;
  background-color: #fff;
  opacity: 1;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
}


@media screen and (min-height: 900px) {
  .iphone {
    zoom: 1;
  }
}

@media screen and (min-height: 820px) and (max-height: 900px) {
  .iphone {
    zoom: 0.9;
  }
}

@media screen and (max-height: 820px) {
  .iphone {
    zoom: 0.8;
  }
}

@media screen and (max-height: 720px) {
  .iphone {
    zoom: 0.7;
  }
}

@media screen and (max-height: 540px) {
  .iphone {
    zoom: 0.6;
  }
}
@media screen and (min-height: 900px) {
  .middle-wrapper {
    zoom: 1;
  }
}

@media screen and (min-height: 820px) and (max-height: 900px) {
  .middle-wrapper {
    zoom: 0.9;
  }
}

@media screen and (max-height: 820px) {
  .middle-wrapper {
    zoom: 0.8;
  }
}

@media screen and (max-height: 720px) {
  .middle-wrapper {
    zoom: 0.7;
  }
}

@media screen and (max-height: 540px) {
  .middle-wrapper {
    zoom: 0.6;
  }
}
