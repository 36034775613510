.fezhCh {
  margin: 0 !important;
  border-radius: 0px 0px 50px 50px;
  z-index: 999;
  // padding-bottom: 80px;
}
.fezhCh {
  border-radius: 0px 0px 20px 20px;
  background-color: #080b27;
  transition-property: transform;
  transform: scale(1);
  transform-origin: 50% 0px;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.fezhCh::before {
  border-radius: 0px 0px 50px 50px;
}
.fezhCh::before {
  content: '';
  position: absolute;
  inset: 0px;
  // background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  // opacity: 0.7;
  border-radius: 0px 0px 20px 20px;
}
.dSPsJO {
  border-radius: 0px 0px 50px 50px;
  background-position: center center;
  z-index: 999;
  // padding-bottom: 80px;
}
.dSPsJO {
  // padding-left: 40px;
  // padding-right: 40px;
}
.dSPsJO {
  border-radius: 0px 0px 20px 20px;
  background-color: #080b27;
  transition-property: transform;
  transform: scale(0.98);
  transition-duration: 1000ms;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transform-origin: 50% 0px;
}
.dSPsJO::before {
  border-radius: 0px 0px 50px 50px;
}
.dSPsJO::before {
  content: '';
  position: absolute;
  inset: 0px;
  border-radius: 0px 0px 20px 20px;
}
.item-max-w {
  max-width: 800px;
}

.item {
  @apply flex flex-col items-center w-full justify-center;
  position: relative;
  max-width: 1000px;
  color: #fff;
  margin-top: 180px;
  z-index: 99;
}

.item-lg-title {
  font-weight: 700;
  font-size: 42px;
  line-height: 58px;
}
.item-desc {
  font-weight: 400;
}

.action-btn {
  margin-top: 80px;
  position: relative;
  overflow: hidden;
  font-weight: 700;
  backdrop-filter: blur(4px);
  padding: 5px;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: linear-gradient(
    89.96deg,
    rgba(255, 255, 255, 0.05) 0.03%,
    rgba(255, 255, 255, 0.008) 49.67%,
    rgba(255, 255, 255, 0.05) 99.96%
  );
}
.action-btn span {
  background: linear-gradient(264.28deg, #dec7ff -38.2%, #5c27fe 103.12%);
  padding: 11px 43px;
  border-radius: 10px;
  color: #fff;
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  font-size: 16px;
}
.action-btn span::after {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.13) 0,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0)
  );
  content: '';
  height: 200%;
  left: -210%;
  opacity: 0;
  position: absolute;
  top: -50%;
  transition: all 0.7s ease 0s;
  width: 200%;
}
.action-btn:hover span::after {
  cursor: pointer;
  left: -30%;
  opacity: 1;
  top: -20%;
  transition-duration: 0.7s, 0.7s, 0.15s;
  transition-property: left, top, opacity;
  transition-timing-function: linear;
}

.image-ai {
  margin-top: 5rem;
}