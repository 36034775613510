:root {
  --black-font-main: #1a1a1a;
  --black-font: #6e6e73;
  --gray-dark: #86868c;
  --bg-l: #080b27;
  --gray-border: #d7d7d9;
  --primary-color: #141416;
  --blue-span: #0084ff;

  --primary-font-color: #f5f5f7;
  --secondary-font-color: #b9b9bf;
  --lighter-menu-link-color: #d7d7d9;
  --light-menu-link-color: #a1a1a6;
  --lightwhite: #d7d7d9;
  --light-font-color: #6e6e6e;
  --lighter-font-color: #6e6e73;
  --button-bg-color: #f5f5f7;
  --button-bg-active-color: #2997ff;
  --button-primary-color: #191919;
  --button-border-color: #6e6e73;
  --contact-lightblue: #b3dfff;
  --contact-button-back: #f5f5f7;
  --contact-button-border: #d7d7d9;
}

@import './styles/button.scss';
@import './styles/contact.scss';

// imports
@import './components/Header/toggle-menu.scss';
@import './components/SecondaryBtn/secBtn.scss';
// @import "./components/ThemeSwitch/theme-switch.scss";
@import './components/MServiceHead/m-service-head.scss';

* {
  margin: 0;
  padding: 0;
}
html {
  font-size: calc(0.95rem + 1vw);
}
body {
  margin: 0;
  padding: 0;
  // font-family: "Poppins", sans-serif;
  font-weight: 500;
  background-color: var(--bg-l) !important;
  width: 100vw !important;
  position: relative;
  overflow-x: hidden;
  height: 100vh;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
body::-webkit-scrollbar {
  height: 0;
  width: 2px;
  color: transparent;
}
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  border-radius: 1px;
}
body::-webkit-scrollbar-thumb {
  border-radius: 2px;
  height: 32px;
  background-color: #707070;
}
.lock-scroll {
  overflow: hidden;
}
.shape {
  content: '';
  position: absolute;
  width: 200vw;
  height: 2000px;
  // top: -70%;
  left: 0;
  z-index: -1;
  background: radial-gradient(34.98% 34.98% at 50% 50%, #725bda 0, rgba(6, 19, 45, 0) 100%);
  opacity: 0.5;
}
.shape.right {
  left: auto;
  right: 0;
}
#viewport {
  // top: 0;
  // left: 0;
  // position: relative;
  // height:200vh;
  background-color: var(--bg-l);
  // background-color: #ccc;
  // overflow: hidden !important;
  color: var(--black-font-main);
}

a {
  text-decoration: none;
}

button {
  background: none;
  outline: none;
  font-family: 'Poppins', sans-serif;
}

.btn {
  padding: 8px 17px 10px;
  border-radius: 100px;
  font-size: 11.2px;
  font-weight: 600;
}
.downloadViewTop {
  z-index: 1000!important;
}
.topHeaderBg {
  // background-color: rgba(8, 11, 39, 0.6);
  background: linear-gradient(rgba(8, 11, 39, 1), rgba(8, 11, 39, 0.8));
  // background: linear-gradient( red 0%, blue 100%);
}
.frame-wrapper {
  .frame {
    position: fixed;
    top: 0;
    left: 0;
    // background-color: rgba(8, 11, 39, 0.7);
    z-index: 995;
    &.f-top {
      z-index: 998;
      // z-index: 0;
      height: 9vh;

      > div {
        height: 100%;
        // img {
        //   position: absolute;
        //   left: 19vw;
        // }
      }
      .logo {
        // height: 100%;
        // top: 0;
        // position: relative;
        // display: flex;
        // justify-content: center;
        // align-items: flex-start;
        // flex-direction: column;

        img.sm-logo {
          height: 50%;
          width: 80px;
          // position: absolute;
          // top: 22.5px;
          // left: 100px;
        }

        img:not(.sm-logo) {
          // height: 45%;
          // width: 42px;
          // opacity: 1;
          // left: 100px;
          // top: 20px;
          // position: relative;
          // display: flex;
          // justify-content: flex-start;
        }
      }

      .navlinks-contain {
        z-index: 999;
        display: flex;
        align-items: center;
        // float: right;
        // transform: translateY(-52%);
        // top: 50%;
        // position: absolute;
        // right: 16vw;
        font-weight: 500;
        font-size: 12.5px;
        li {
          list-style: none;
          display: list-item;
          padding: 0 0 0 27px;
          // transition: opacity 0.3s ease;
          &.dn {
            display: none;
          }
          a {
            color: #fff;
            opacity: 0.8;
            text-decoration: none;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            // transition: all 0.3s ease;
            &:hover {
              color: #7b60f6;
              // opacity: 0.6;
            }

            &.active {
              color: #7b60f6;
              opacity: 0.6;
            }
          }

          &.fade-out {
            opacity: 0;
          }
        }

        li.estimateBtn {
          cursor: pointer;
          padding: 20px 35px 20px 42px;
          // margin-top: -1px;
          .creator-center-btn-wrap {
            background: var(--primary-color);
            border-radius: 24px;
            height: 34px;
            position: relative;
            width: 93px;
            overflow: hidden;
            pointer-events: none;
            p {
              padding: 5px;
              color: white;
              position: relative;
              top: 50%;
              // transform: translateY(-50%);
              background: none;
              left: 0px;
              pointer-events: none;
            }
          }

          a {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            font-size: 0.73vw;
            font-weight: 600;
            padding: 0;
            pointer-events: none;
            transition: background-color 0.3s ease;
            letter-spacing: 0;

            &:hover {
              background-color: var(--button-bg-active-color);
              color: white;
            }
          }
          &.con-dn {
            padding-right: 0;
            .creator-center-btn-wrap {
              display: none;
            }
          }
        }
        .disNone {
          display: none;
        }
        li.mobile-theme-swithcer {
          display: none;
        }

        .menu {
          // transform: translate(0px, 5px);
          padding: 0;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          position: relative;
          user-select: none;

          .menu-trigger {
            user-select: none;
            position: absolute;
            // top: -23%;
            // left: -35%;
            inset: 0;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 140%;
            height: 30px;
            opacity: 0.6;
          }

          .menuLines {
            // transition: all 0.3s ease;
            display: block;
            width: 27px;

            &.l1 {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }

  .f-bottom,
  .f-top {
    padding: 0 3.646vw;
    // height: 3.646vw;
    height: 55px;
    width: calc(100%);
  }

  .f-top {
    top: 0;
  }

  .f-bottom {
    bottom: 0;
    pointer-events: none;
  }
}
.frame-wrapper-h5 {
  .frame {
    // position: fixed;
    position: absolute;
    z-index: 995;
    &.f-top {
      z-index: 998;
      background-color: var(--bg-l) !important;
      // height: 8.2vh;
      // padding-top: 0.5vh;
      color: #fff !important;
      > div {
        height: 100%;
        img {
          position: absolute;
          left: 0vw;
        }
      }
      .logo {
        height: 100%;
        top: 0;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        img.sm-logo {
          // height: 45%;
          // width: 128px;
          position: absolute;
          top: 22.5px;
        }

        img:not(.sm-logo) {
          // height: 45%;
          // width: 42px;
          opacity: 1;
          top: 20px;
          position: relative;
          display: flex;
          justify-content: flex-start;
        }
      }

      .navlinks-contain {
        z-index: 999;
        display: flex;
        align-items: center;
        //float: right;
        //transform: translateY(-52%);
        top: 50%;
        position: absolute;
        right: 14px;
        font-weight: 500;
        font-size: 12.5px;
        li {
          list-style: none;
          display: list-item;
          padding: 0 0 0 27px;
          // transition: opacity 0.3s ease;
          &.dn {
            display: none;
          }
          a {
            color: #fff;
            opacity: 0.8;
            text-decoration: none;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            // transition: all 0.3s ease;
            &:hover {
              color: #7b60f6;
              // opacity: 0.6;
            }

            &.active {
              color: #7b60f6;
              opacity: 0.6;
            }
          }

          &.fade-out {
            opacity: 0;
          }
        }

        li.estimateBtn {
          cursor: pointer;
          padding: 20px 35px 20px 42px;
          // margin-top: -1px;
          .creator-center-btn-wrap {
            background: var(--primary-color);
            border-radius: 24px;
            height: 34px;
            position: relative;
            width: 93px;
            overflow: hidden;
            pointer-events: none;
            p {
              padding: 5px;
              color: white;
              position: relative;
              top: 50%;
              // transform: translateY(-50%);
              background: none;
              left: 0px;
              pointer-events: none;
            }
          }

          a {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            font-size: 0.73vw;
            font-weight: 600;
            padding: 0;
            pointer-events: none;
            transition: background-color 0.3s ease;
            letter-spacing: 0;

            &:hover {
              background-color: var(--button-bg-active-color);
              color: white;
            }
          }
          &.con-dn {
            padding-right: 0;
            .creator-center-btn-wrap {
              display: none;
            }
          }
        }
        .disNone {
          display: none;
        }
        li.mobile-theme-swithcer {
          display: none;
        }

        .menu {
          // transform: translate(0px, 5px);
          padding: 0;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          position: relative;
          user-select: none;

          .menu-trigger {
            user-select: none;
            position: absolute;
            // top: -23%;
            // left: -35%;
            inset: 0;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 140%;
            height: 30px;
            opacity: 0.6;
          }

          .menuLines {
            // transition: all 0.3s ease;
            display: block;
            width: 27px;

            &.l1 {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }

  .f-bottom,
  .f-top {
    padding: 0 3.646vw;
    // height: 3.646vw;
    height: 55px;
    width: calc(100%);
  }

  .f-top {
    top: 0;
  }

  .f-bottom {
    bottom: 0;
    pointer-events: none;
  }
}
section {
  margin: 3.646vw;
  // width: calc(100vw - 7.3vw);
  // color: var(--primary-font-color);
  // padding-bottom: 40%;
  text-align: center;
  position: relative;

  &.head {
    h1 {
      position: relative;
      z-index: 10;
      display: inline-block;
      font-size: 54px;
      letter-spacing: 1px;
      font-weight: 500;
      color: white;
    }

    .h1 {
      height: calc(100vh - 7.3vw);
      display: flex;
      align-items: center;
      justify-content: center;

      h1 {
        span.headSpan {
          display: inline-block;
          overflow: hidden;
          span {
            // padding: 0.4rem 0px;
            display: inline-block;
          }
        }
      }

      &.mobile-home-head {
        position: absolute;
        top: 0;
        right: 0;
        h1 .call-action {
          margin-top: 30px;
          display: grid;
          height: fit-content;
          overflow: hidden;

          a.estimate-btn {
            opacity: 0;
            width: fit-content;
            display: block;
            margin: 0 auto;
            background-color: #fff;
            color: var(--bg-l);
            font-size: 0.73rem;
            font-weight: 600;
            padding: 16px 25px;
            // transition: all 0.3s ease;
            letter-spacing: 0;
          }

          a.view-projects-btn {
            opacity: 0;
            margin: 20px auto 0;
            width: fit-content;
            font-size: 0.89rem;
            border-bottom: 2px solid var(--black-font);
            color: var(--black-font-main);
            padding-bottom: 3px;
          }
        }
      }
    }

    .h2 {
      position: relative;
      // top: 30%;
      z-index: 10;
      padding-top: 140px;
      // background: black;
      background: none;
      text-align: center;

      h3 {
        font-size: 1.48rem;
        text-align: center;
        line-height: 1.43;
        margin: 77px auto 37px;
        font-weight: 500;
        letter-spacing: 0.4px;
      }

      h6 {
        font-size: 0.79rem;
        font-weight: 400;
        color: #ceabab;
        letter-spacing: 0.2px;
      }
      &.home {
        padding-top: 0;
        pointer-events: none;
      }
    }

    .btn-container {
      margin-top: 37px;
      margin-bottom: 10px;
    }

    a.btn-more {
      // color: var(--primary-font-color);
      text-decoration: none;
      border: 1px solid #e7c8c8;
    }
  }

  &.process {
    padding: 19vh 0 0;
    z-index: 10;
    height: 100vh;
    margin: 0;
    background-size: contain;
    h3 {
      font-size: calc(1.525rem + 5px);

      color: var(--black-font-main);
      margin: 0 auto 3.65rem;
      font-weight: 500;
      line-height: 1.28;
      letter-spacing: 0.4px;

      position: absolute;
      top: calc(-5.6rem + -4vh);
      left: 50%;
      transform: translateX(-50%);

      span {
        color: var(--button-bg-active-color);
      }
    }

    .process-grid {
      img {
        z-index: 2;
        right: 26px;
        width: 75px;
        top: 24px;
        position: absolute;
      }
      position: absolute;
      left: 38%;
      text-align: left;
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      width: 103%;
      margin: auto;
      grid-column-gap: 42px;
      top: calc(-38px + 0.3rem);

      // a{
      //   color:var(--primary-font-color);
      //   position: relative;
      // }
      h5 {
        font-size: 23.1px;
        color: var(--black-font-main);
        letter-spacing: 0.3px;
        font-weight: 500;
        padding-top: 2px;
        width: fit-content;
        white-space: nowrap;
      }

      p {
        font-size: 13.84px;
        margin-top: 15px;
        letter-spacing: 0.15px;
        line-height: 1.64;
        color: var(--black-font);
        span {
          color: var(--blue-span);
        }
      }

      .process-col {
        -webkit-box-shadow: 0px 3px 30px 0px #00000005;
        -moz-box-shadow: 0px 3px 30px 0px #00000005;
        box-shadow: 0px 3px 30px 0px #00000005;
        background: #fff;
        box-shadow: 0px 3px 30px 0px rgb(0 0 0 / 2%);
        padding: 50px 46px 50px;
        border-radius: 40px;
        position: relative;
        overflow: hidden;
        &:first-child {
          cursor: pointer;
          p,
          img,
          a {
            pointer-events: none;
          }
          .ripple {
            content: '';
            position: absolute;
            width: 100%;
            top: 0;
            height: 100%;
            z-index: 1;
            border-radius: 50%;
            background: #f5f5f7;
            opacity: 0;
            left: 0;
            mix-blend-mode: difference;
          }
        }
      }
    }
  }

  &.sec-form {
    max-width: 34%;
    max-width: 18.4rem;
    margin: auto;

    &.sending-form-effect {
      opacity: 0.8;
    }

    h6 {
      font-size: 33.17px;
      font-weight: 500;

      p {
        display: inline-block;
        color: var(--contact-lightblue);
      }

      br {
        display: none;
      }
    }

    form {
      text-align: left;
      margin-bottom: 6.6rem;

      div {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-around;
        justify-content: space-between;
        align-items: center;
        align-content: center;

        &.mt {
          margin-top: -14px;
        }

        &.btn-container {
          margin-bottom: 0;
          padding-bottom: 0;
          justify-content: center;
        }
      }

      div > p {
        margin-bottom: 13.6px;
        color: var(--gray-dark);
        font-size: 0.645rem;
        margin-left: 5px;
        margin-top: 15px;
        flex-basis: 100%;
      }

      div.form-inputs {
        margin: 2.8rem 6px 1.1rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 33px;
        grid-column-gap: 54px;
        justify-content: space-between;

        .am-input {
          margin-bottom: 0;
          position: relative;
          overflow: hidden;
          height: 1.45rem;

          padding-bottom: 21px;

          input,
          textarea {
            background: none;
            border: none;
            width: 100%;
            padding-bottom: 0px;
            font-size: 0.49rem;
            // border-bottom: .8px solid #707070;
            font-weight: 400;
            font-family: 'Poppins';
            letter-spacing: 0.1px;
            -moz-appearance: none;
            outline: 0px none transparent;
            resize: none;
            transition: all 0.3s ease;
            color: var(--black-font-main);
          }
          input::placeholder,
          textarea::placeholder {
            color: var(--black-font);
            font-weight: 500;
            font-size: 0.49rem;
          }
          textarea {
            padding-bottom: 4px;
            height: 23px;
            height: 0.78rem;
            overflow: hidden;
            &::placeholder {
              color: var(--black-font);
              font-weight: 500;
            }
            &::-webkit-input-placeholder {
              color: var(--black-font);
            }
            &::-webkit-input-placeholder {
              color: var(--black-font);
            }

            &::-moz-placeholder {
              color: var(--black-font);
            }
            &:-ms-input-placeholder {
              color: var(--black-font);
            }
            &:-moz-placeholder {
              color: var(--black-font);
            }
          }

          .input-moving-line {
            margin-bottom: 0 !important;
            width: 300%;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 15px;
            bottom: 0.53rem;
            height: 59px;
            -webkit-transform: translateX(-66.66%);
            -moz-transform: translateX(-66.66%);
            -ms-transform: translateX(-66.66%);
            -o-transform: translateX(-66.66%);
            transform: translateX(-66.66%);
            opacity: 0.6;
            background-position: center bottom;
            pointer-events: none;
            transition: transform 0s cubic-bezier(0, 0.25, 0.5, 1), opacity 0.4s,
              -webkit-transform 0s cubic-bezier(0, 0.25, 0.5, 1),
              -moz-transform 0s cubic-bezier(0, 0.25, 0.5, 1),
              -o-transform 0s cubic-bezier(0, 0.25, 0.5, 1);
          }

          input.err ~ .input-moving-line {
            opacity: 0.8;
          }

          span.input-err {
            position: absolute;
            bottom: 0px;
            left: 0;
            font-size: 0.4rem;
            font-weight: 400;
            color: #ff6666;
          }

          &:hover {
            .input-moving-line {
              opacity: 1 !important;
            }
          }

          &:focus-within {
            .input-moving-line {
              opacity: 1 !important;
              -webkit-transform: translateX(0);
              -moz-transform: translateX(0);
              -ms-transform: translateX(0);
              -o-transform: translateX(0);
              transform: translateX(0);
              -webkit-transition-duration: 0.8s, 0.4s;
              -moz-transition-duration: 0.8s, 0.4s;
              -o-transition-duration: 0.8s, 0.4s;
              transition-duration: 0.8s, 0.4s;
            }
          }
        }
      }

      div.options-btns {
        // justify-content: flex-start;

        a.sec-btn {
          // margin-right: 1rem;

          span {
            font-weight: 400;
            color: var(--black-font-main);
            font-size: 0.49rem;
          }
        }

        a.sec-btn:last-child {
          margin-right: 0 !important;
        }
      }

      a {
        display: inline-block;
        // margin: 1.3px 0 9.5px 0;
        margin: 0.04rem 0 0.33rem 0;
        letter-spacing: 0.15px;
        font-size: 12.3px;
        font-weight: 400;

        &.btn {
          // padding: 14.5px 20.5px 13.7px;
          padding: 0.49rem 0.69rem 0.47rem;
          border-radius: 32px;
          border: 1.2px solid var(--contact-button-border);
        }
      }

      button.btn {
        border: 1px solid var(--secondary-font-color);
        background: none;
        color: var(--black-font-main);
        position: relative;
        padding: 9px 17px 9px 30px;
      }

      .att-btn {
        position: static;
        justify-content: left;
        margin-top: -10px;
        justify-content: space-between;
        margin-bottom: 1.1rem !important;

        label {
          display: none;
        }

        .contact-action {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          a {
            margin: 0;
          }
        }

        a.btn {
          padding: 14.5px 20.5px 14.7px 20.5px;

          span {
            position: relative;
            padding-left: 20px !important;
            fill: var(--black-font-main);
            color: var(--black-font-main);
            font-weight: 500;
            font-size: 0.49rem;

            svg {
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 14px;
              fill: inherit;
            }
          }
        }

        input {
          position: absolute;
          left: 0;
        }

        .attachs {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin-top: 25px;
          margin-bottom: 0;
          height: auto !important;

          .attach {
            display: flex;
            align-items: center;
            color: var(--black-font-main);
            cursor: pointer;
            margin-right: 30px;
            margin-bottom: 15px;

            svg {
              width: 24px;
              height: 24px;
              color: var(--black-font-main);
            }

            span {
              margin-left: 5px;
              font-size: 0.45rem;
            }

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }

      div.options-btns.budgets {
        margin-bottom: 39px;
        position: relative;
        padding-bottom: 11px;
        .input-err {
          position: absolute;
          bottom: 0px;
          left: 5px;
          font-size: 0.4rem;
          font-weight: 400;
          color: #ff6666;
        }

        // a.sec-btn {
        //   margin-right: 0.4rem;
        // }
      }
    }
  }

  &.services.head {
    height: 100vh;
    position: relative;
    img {
      width: 60px;
      margin-top: 2rem;
    }
    .h2 {
      padding-bottom: 2%;
      padding-top: 0;
      h6 {
        // font-size: 15.4px;
        font-size: 0.62rem;
        color: var(--gray-dark);
        font-weight: 600;
        letter-spacing: 0;
      }

      h3 {
        width: 90%;
        margin-top: 53px;
        color: var(--black-font-main);
      }
    }
  }
}

.hd {
  visibility: hidden;
}

.nd {
  display: none;
}

.mb {
  margin-bottom: 55px !important;
}

.center {
  position: absolute;
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -50%);
}
.centerized {
  position: absolute;
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, calc(-50% + 30px + 1rem));
}

.full-w {
  grid-column-end: 3;
  grid-column-start: 1;
}

.full-h {
  height: calc(100vh - 7.3vw);
}

.sec-active {
  position: fixed;
  top: 50%;
  width: 389px;
  height: 396px;
}

.backLayer {
  background-image: linear-gradient(225deg, #5a81a2 0%, #1f3d61 100%);
  // position: fixed;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
.contact-page .sec-form.mb .btn-container .btn-more {
  border: 1px solid var(--contact-button-border);
}

.can {
  width: 100%;
  height: 500px;
}
@keyframes scrollDown {
  0% {
    transform: rotate(-90deg) translateX(0);
  }

  35% {
    transform: rotate(-90deg) translateX(-8px);
  }

  100% {
    transform: rotate(-90deg) translateX(0);
  }
}

@import './styles/_responsive.scss';
