.video_play {
  width: 100vw;
  height: 100vh;
  margin-top: -10vh;
}
.opacity-video {
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  background-color: #000;
  z-index: 99;
  opacity: 0;
}
.videoElement {
  height: 100vh;
  object-fit: fill;
}
// .video-js .vjs-duration, .vjs-no-flex .vjs-duration {
//     display: inline-block;
//   }
//   .video-js .vjs-current-time, .vjs-no-flex .vjs-current-time {
//     display: inline-block;

//   }

.image-compare-container {
  position: absolute;
  width: 400px;
  z-index: 9999999!important;
}