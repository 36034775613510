.menu-wrapper {
  width: 0;
  height: 0;
  position: fixed;
  top: 0;
  overflow: hidden;
  right: 0;
  color: var(--primary-font-color);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: var(--bg-l);
  border-radius: 0 0 0 100%;

  &.open {
    z-index: 998;
  }

  &.close {
    z-index: -10;
    display: none;
  }

  .bg-overlay {
    position: absolute;
    top: 0;
    left: inherit;
    right: 0;
    // bottom: 0;

    width: 100vw;
    height: 100vh;
    // opacity: 0;
    transition: opacity 0.6s ease;
    display: none;

    &.fade-in {
      display: block;
      // opacity: 0.7;
    }
  }

  span,
  a {
    font-weight: 500;
  }

  .menu-bg-box {
    overflow: hidden;
    display: flex;
    perspective: 2000px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }

  .menu-box {
    // min-width: 50vw;
    max-width: 60vw;
    height: auto;
    // max-height: 66vh;
    width: 44.7vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: transparent;
    position: absolute;
    // transform: translateY(-100vh);
    // max-height: 0;
    // transition: max-height 0.15s ease-out;
    .menu-left-part,
    .menu-right-part {
      pointer-events: bounding-box;
    }
    .menu-tilt {
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
      background: #000;
      // transform: translateY(-100vh);
    }

    & > div {
      width: auto;
      min-height: 10.4rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    & > div:not(.menu-tilt) {
      // opacity: 0;
    }

    .menu-left-part {
      // margin-right: 17%;

      > div {
        display: flex;
        text-align: left;
        flex-direction: column;

        > span.head {
          color: var(--black-font-main);
          font-size: 0.34rem;
          line-height: 2;
          position: relative;
          display: inline-block;
          padding: 4px 12px 0;
        }

        a,
        p {
          &:first-child {
            margin-top: -5px;
          }
          position: relative;
          width: min-content;
          height: max-content;
          overflow: hidden;
          display: flex;
          &.email {
            width: 100%;
            cursor: none;
          }

          span {
            color: var(--black-font);
            transition: color 0.4s linear;
            font-size: 0.44rem;
            line-height: 2;
            padding: 0.3vw 0.4rem;
            font-weight: 600;
          }

          svg {
            left: 0;
            bottom: 0px;
            position: absolute;
            width: 300%;
            stroke: #707070;
            height: 7vw;
            -webkit-transform: translateX(-66.66%);
            -moz-transform: translateX(-66.66%);
            -ms-transform: translateX(-66.66%);
            -o-transform: translateX(-66.66%);
            transform: translateX(-66.66%);
            transition: all 0s cubic-bezier(0, 0.25, 0.5, 1),
              -webkit-transform 0s cubic-bezier(0, 0.25, 0.5, 1),
              -moz-transform 0s cubic-bezier(0, 0.25, 0.5, 1),
              -o-transform 0s cubic-bezier(0, 0.25, 0.5, 1);
          }

          &:hover {
            span {
              color: var(--black-font-main);
            }

            svg {
              stroke: var(--primary-font-color);
              -webkit-transform: translateX(0);
              -moz-transform: translateX(0);
              -ms-transform: translateX(0);
              -o-transform: translateX(0);
              transform: translateX(0);
              -webkit-transition-duration: 0.6s;
              -moz-transition-duration: 0.6s;
              -o-transition-duration: 0.6s;
              transition-duration: 0.6s;
            }
          }
        }
      }
      // div.mid{ padding-top:55px}
    }

    .menu-right-part {
      span.head {
        color: var(--black-font-main);
        // font-size: 10px;
        font-size: 0.34rem;
        line-height: 2.15;
        position: relative;
        padding: 0 15px;
        text-align: left;
      }
      a {
        // color: var(--secondary-font-color);
        color: var(--black-font);
        line-height: 1;
        user-select: none;
        -webkit-touch-callout: none; /* Safari */
        -webkit-user-select: none; /* Chrome */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */

        // transition: all 0.3s ease;
        font-size: 2.6vw;
        font-size: 1.262rem;
        overflow: hidden;
        position: relative;
        display: flex;
        width: min-content;
        // padding-bottom: 13px;
        padding: 0.51rem;
        margin-bottom: 2px;

        &.active {
          color: var(--black-font-main);

          &::after {
            left: 0;
          }
        }

        &:hover {
          color: var(--black-font-main);

          &::after {
            left: 0;
          }
        }
        &.fc {
          padding: 0.17rem 0.51rem 0.51rem;
        }
        &:last-child {
          padding: 0.51rem 0.51rem 0.17rem;
        }

        // &::after {
        //   content: "";
        //   width: 100%;
        //   height: 3px;
        //   position: absolute;
        //   bottom: 1px;
        //   left: -100%;
        //   background-color: var(--primary-font-color);
        //   transition: all 0.3s ease;
        // }
      }
    }

    .bottom-part {
      display: none;
      height: auto !important;
      min-height: 20vh;
      width: 100%;
      text-align: center;
      pointer-events: all;

      a.estimate-btn {
        display: block;
        // max-width: 400px;
        width: 90%;
        margin: 0 auto;
        background-color: var(--button-bg-color);
        color: var(--button-primary-color);
        font-size: 0.86rem;
        font-weight: 600;
        padding: 18px 0;
        margin-bottom: 5%;
        transition: all 0.3s ease;
        letter-spacing: 0;

        &:hover {
          background-color: var(--button-bg-active-color);
          color: var(--primary-font-color);
        }
      }

      .header-socials {
        margin: 0 auto;
        width: 90%;
        // max-width: 360px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        a {
          &:nth-child(1) svg {
            // height: 18px;
            height: 1.3rem;
          }

          &:nth-child(2) svg {
            // height: 16px;
            height: 1.1rem;
          }

          &:nth-child(3) svg {
            // height: 21px;
            height: 1.1rem;
          }

          &:nth-child(4) svg {
            // height: 22px;
            height: 1rem;
          }

          &:nth-child(5) svg {
            height: 1.1rem;
          }
        }

        svg {
          height: 18px;
          // fill: var(--light-menu-link-color);
          display: flex;
          align-items: center;
          justify-content: center;

          // path {
          //   // fill: var(--light-menu-link-color);
          // }

          &.linkedin {
            height: 21px;
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .menu-wrapper {
    .menu-box {
      // width: 720px;
      // max-width: 75vw;

      .menu-left-part {
        margin-right: 17%;

        > div {
          > span.head {
            // font-size: 10px;
            // line-height: 21px;
          }

          a {
            span {
              // font-size: 13px;
              // line-height: 26px;
              padding-bottom: 2.392px;
            }
          }
        }
      }

      .menu-right-part {
        a {
          // font-size: 37.5024px;
          // padding-bottom: 5px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .menu-wrapper {
    // background: url(../../assets/menu.jpg);
    // background-size: 375px auto;
    // background-position: -190px -91px;
    .bg-overlay {
      display: none;
    }
    .menu-bg-box {
      top: 54%;
    }
    .menu-box {
      max-width: 100vw;
      width: 100vw;
      height: 100vh !important;
      padding: 0 !important;
      padding-top: 10vh !important;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-height: 100vh;
      overflow: hidden;
      // background: #000;

      & > div:not(.menu-tilt) {
        opacity: 1;
      }

      .menu-left-part {
        display: none !important;
      }

      .menu-right-part {
        align-items: center;
        margin-bottom: 4.5%;
        min-height: 14.4rem;
        opacity: 1;
        visibility: inherit;

        a {
          font-size: 2.2rem;

          &:hover::after {
            left: -100%;
          }

          &::after {
            bottom: 0px;
          }
        }
      }
      .menu-box .bottom-part,
      .menu-right-part a {
        opacity: 0;
        visibility: hidden;
      }
      .bottom-part {
        display: block;
        padding-top: 23px;
        a.estimate-btn {
          background-color: #0084ff;
          color: var(--bg-l);
        }
        .header-socials a svg {
          height: 20px;
          width: 100%;
          // fill:var(--black-font-main)
          path {
            fill: var(--black-font-main);
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .menu-wrapper {
    .menu-box {
      .menu-bg-box {
        top: 50%;
      }
      .menu-right-part {
        margin-top: 10%;
        min-height: 39vh;
        margin-bottom: 11.5%;

        a {
          font-size: 2.15rem;
        }
      }

      .bottom-part {
        a.estimate-btn {
          max-width: 91vw !important;
          padding: 18px 0;
          font-size: 1rem;
          margin-bottom: 7.3%;
        }

        .header-socials {
          max-width: 80% !important;

          a svg {
            height: 19px;

            &.whats {
              height: 21px;
            }

            // &:nth-child(2) {
            //   height: 18px;
            // }

            // &:nth-child(3) {
            //   height: 24px;
            // }

            // &:nth-child(4) {
            //   height: 23px;
            // }

            // &:nth-child(5) {
            //   height: 23px;
            // }
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .menu-wrapper {
    .menu-box {
      .menu-right-part {
        margin-top: 13%;
        min-height: 42vh;
        margin-bottom: 10%;

        a {
          font-size: 2.35rem;
        }
      }

      .bottom-part {
        a.estimate-btn {
          max-width: 90vw !important;
          padding: 15px 0;
          margin-bottom: 9.5%;
        }

        .header-socials {
          max-width: 74% !important;
        }
      }
    }
  }
}
