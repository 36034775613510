/* @tailwind base; */
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Alibaba-PuHuiTi-Regular';
  src: url(https://puhuiti.oss-cn-hangzhou.aliyuncs.com/AlibabaPuHuiTi/Alibaba-PuHuiTi-Regular/Alibaba-PuHuiTi-Regular.woff2)
    format('woff2');
}

@font-face {
  font-family: 'Alibaba-PuHuiTi-Bold';
  src: url(https://puhuiti.oss-cn-hangzhou.aliyuncs.com/AlibabaPuHuiTi/Alibaba-PuHuiTi-Bold/Alibaba-PuHuiTi-Bold.woff2)
    format('woff2');
}

@font-face {
  font-family: 'Alibaba-PuHuiTi-Medium';
  src: url(https://puhuiti.oss-cn-hangzhou.aliyuncs.com/AlibabaPuHuiTi/Alibaba-PuHuiTi-Medium/Alibaba-PuHuiTi-Medium.woff2)
    format('woff2');
}

body {
  margin: 0;
  font-family: 'Alibaba-PuHuiTi-Regular', 'Alibaba-PuHuiTi-Medium', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-y: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ph-bold {
  font-family: 'Alibaba-PuHuiTi-Bold';
}

.ph-medium {
  font-family: 'Alibaba-PuHuiTi-Medium';
}

.overlay {
  position: fixed;
  inset: 0;
  grid-area: 1 / 1 / 2 / 2;
  z-index: 999;
  pointer-events: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  fill: #f5f5f7;
  color: #f5f5f7;
}

.overlay.hidden {
  display: none;
}

.overlay {
  /* fill: #000;
  color: #000; */
}

.ct-toast-success {
  color: #ffffff !important;
  background: #141416 !important;
  border-left: 0 !important;
}
.ct-toast-success svg {
  display: none;
}

.ct-toast-success .ct-text-group {
  margin-left: 0;
}
