section.head {
  position: static;
  margin-top: 0;
  margin: 0;
  // margin-bottom: 90vh;
  .gl-back{
  pointer-events: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
  .h1 {
    height: 100vh;
    width: 100vw;
    // display: flex;
    // flex-direction: column;
    position: relative;
    // left: 50%;
    // transform: translateX(-50%);
    z-index: 800;
    // cursor: pointer;
    .headSpan span {
      color: white !important;
      opacity: 0.85;
      // cursor: pointer;
    }
    .head-ml span {
      margin-left: 18px;
    }
  
  }
  .h2-wrapper {
    position: absolute;
    top: 0;
    height: 100vh;
    left: 0;
    width: 100vw;
    z-index: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    .h2-con {
      margin-top: 90px;
      width: 100%;
      opacity: 0;
      h6{
        font-weight: 600;
        font-size: 2.5vw;
        color: white !important;
      }
      div{
        // margin-top: 5px;
      }
      h3 {
        margin-top: 45px;
        font-size: 18px;
        color: white !important;
        // color: var(--black-font-main);
      }
    }
  }
  div.darkLay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    // background: black;
    // opacity: .6;
    z-index: 5;
  }
  div.darkLay2 {
    background: var(--bg-l);
    position: absolute;
    width: 100vw;
    height: 100vh;
    opacity: 0.5;
    z-index: 10;
  }
}
@media (max-width: 768px) {
  section.head div.darkLay2 {
    display: none;
  }
  section.head div.darkLay {
    // background: linear-gradient(180deg, rgba(0,0,0,0.404096672848827) 0%, rgba(0,0,0,0.8522759445575105) 100%);
    height: 100vh;
    top: 0;
    opacity: 1;
  }
  section.head .h1 {
    width: 55vw;
  }

  .h2-wrapper {
    position: static !important;
    margin-top: 0 !important;
    height: 71vh;
    align-items: flex-end;
    .h2-con {
      margin-top: 0 !important;
    }
  }
}
