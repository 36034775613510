section.footer-sec,
div.con-foot {
  position: relative;
  bottom: 0%;
  width: 100%;
  z-index: 1;
  background-size: contain;
  &.fot {
    background-color: #7b60f6;
    height: 20vh;
    // min-height: 594px;
    padding: 0vh 0 1.5rem 0;
  }
  max-width: 100%;
  .footer-main {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 2;
  }
  .footer-foot {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    z-index: 2;
    width: 80%;
    margin: 0 auto;
    &.con-fot {
      width: 100%;
    }
    .footer-secs {
      display: flex;
      margin: 0 5px;
      align-content: space-around;
      flex-direction: column;
      justify-content: center;
      &.l {
        text-align: left;
        position: relative;
        h5 {
          font-size: 13.88px;
          font-size: 0.47rem;
          font-weight: 400;
          position: relative;
          cursor: none;
          // color: var(--black-font-main);
          &:after {
            content: '';
            width: 77%;
            height: 0.7px;
            background-color: var(--black-font-main);
            left: 0;
            bottom: 0px;
            position: absolute;
          }
        }
        .menus {
          display: flex;
          span,
          a {
            color: #080b27;
            font-weight: 500;
            // color: #707070;
            font-size: 14px;
            margin-right: 30px;
          }
        }
      }
      &.r {
        text-align: right;
        div {
          width: 164px;
          // width: 5.9rem;
          display: flex;

          justify-content: space-between;
          align-items: center;
          img {
            margin: auto;
            display: block;
            position: relative;
          }
          .icon {
            height: 18px;
          }
          .icon-link:hover {
            opacity: 0.5;
          }
        }
      }

      p {
        letter-spacing: 0.2px;
        font-size: 9.1px;
        font-size: 0.31rem;
        padding-top: 13px;
        display: block;
        color: var(--black-font);
        padding-right: 3px;
      }
    }
  }
}

.email-input input {
  background: rgba(255, 255, 255, 0.02);
  border-radius: 8px;
  height: 60px;
  width: 600px;
  border: 0.5px solid #b59df9;
  color: white;
  font-size: 18px;
  padding: 0 24px;
}
.email-input input::-webkit-input-placeholder {
  color: white;
  opacity: 0.8;
}
.email-input input:focus {
  outline: none !important;
  border: 0.5px solid #583bf4;
  box-shadow: 0 0 5px #b59df9;
}
.cursor-pointer {
  font-size: 16px !important;
}
.cursor-pointer:hover {
  cursor: pointer;
  // color: #7b60f6 !important;
}
