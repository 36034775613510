div.m-service-head {
  text-align: center;
  margin: 10.2rem 0 6.8rem;

  h6 {
    font-size: 0.97rem;
    color: var(--secondary-font-color);
    font-weight: 400;
  }

  h4 {
    font-size: 2.28rem;
    font-weight: 500;
    color: var(--black-font-main);
  }
}

@media (max-width: 768px) {
  div.m-service-head {
    margin: 207px 0 167px;
    h4 {
      font-size: 1.9rem;
    }
  }
}
@media (max-width: 450px) {
  div.m-service-head {
    margin: 171px 0 128px;
    h4 {
      font-size: 2.28rem;
    }
  }
}

@media (max-width: 395px) {
  div.m-service-head {
    margin: 9.3rem 0 5.9rem;
    letter-spacing: 0.7px;

    h6 {
      font-size: 0.95rem;
    }

    h4 {
      font-size: 2.4rem;
      // margin-top: -0.4rem;
    }
  }
}

@media (max-width: 375px) {
  div.m-service-head {
    margin: 8.3rem 0 5.9rem;

    h6 {
      font-size: 0.73rem;
    }

    h4 {
      font-size: 2.4rem;
      // margin-top: -0.4rem;
    }
  }
}
