.image-compare {
  padding: 2px;
  border-radius: 20px;
  background-image: linear-gradient(103deg, #563bff 0%, #7371fc 43.6%, #d94fd5 100%);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-compare img-comparison-slider.rendered {
  width: 40vw;
  outline: none;
  border: 0;
  border-radius: 20px;
  display: block;
  // max-height: 500px;
  .img1 {
    background: #fbd23c;
  }
  .img2 {
    background: #d9d8d8;
    background: url(../../assets/cover/bg2.jpg) no-repeat center;
    background-size: 100%;
  }
  .before-text,
  .after-text {
    font-size: 12px;
    text-transform: uppercase;
    color: #fff;
    border-radius: 5px;
    min-height: 25px;
    position: absolute;
    display: flex;
    align-items: center;
    min-width: 70px;
    justify-content: center;
    top: 72px;
  }
  .before-text {
    background: rgba(0, 0, 0, 0.31);
    left: 28px;
  }
  .after-text {
    background: #563bff;
    right: 28px;
  }
}
.image-compare img-comparison-slider.rendered img {
  width: 100%;
  border-radius: 20px;
}
.image-compare img-comparison-slider.rendered .handle-image {
  width: 2.4rem;
  height: 2.4rem;
}
